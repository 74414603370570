/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/lightgallery@2.4.0/lightgallery.min.js
 * - /npm/lightgallery@2.4.0/plugins/video/lg-video.min.js
 * - /npm/lightgallery@2.4.0/plugins/thumbnail/lg-thumbnail.min.js
 * - /npm/lightgallery@2.4.0/plugins/pager/lg-pager.min.js
 * - /npm/lightgallery@2.4.0/plugins/hash/lg-hash.min.js
 * - /npm/lightgallery@2.4.0/plugins/fullscreen/lg-fullscreen.min.js
 * - /npm/lightgallery@2.4.0/plugins/autoplay/lg-autoplay.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
